import React, { useEffect, useState } from 'react';
import AppContainer from '../../components/app-container';
import Indicator from '../../components/indicator';
import PieChart from './../../components/charts/PieChart/index';
import { createRecord, requestStatistic } from '../../transport/api';

import styles from './Balance.module.css';
import RecordTable from '../../components/RecordTable';
import AddTitle from '../../components/AddTitle';
import EditModal from './../../components/EditModal/index';
import PhysicalBalance from './PhysicalBalance';
import { getDate, makeTitle } from '../../utils';
import Button from '../../components/button';
import { toast } from 'react-toastify';

const Balance = () => {
  makeTitle('Баланс');
  const [data, setData] = useState({});
  const [newPhysicalBalance, toggleNewPhysicalBalance] = useState(false);
  const [newInvestor, toggleNewInvestor] = useState(false);
  const [investor, setInvestor] = useState();
  const [balance, setBalance] = useState();

  useEffect(() => {
    requestStatistic('/physical-balances/summary').then((data) => setData(() => data));
  }, [newInvestor, investor?.id, balance?.id]);

  return (
    <AppContainer
      title={
        <div className={styles['button-container']}>
          Баланс
          {[0, 3, 6, 9].includes(new Date().getMonth()) ? <Button
            className={styles.button}
            onClick={() => createRecord(`/money-operations/new`, {
              operation_date: getDate(),
              operation_type: 'TAX',
              description: 'Оплата налога',
              is_shared: true,
            })
              .then(() => {
              toast.success('Налоги успешно списаны');
            })
              .catch((error) => toast.error(error.message))}
          >
            Списать налоги
          </Button> : undefined}
        </div>
      }
    >
      <div className={styles.indicators}>
        <Indicator
          label="Тотал баланса"
          value={(data?.total_balance ?? 0) + (data?.stock_balance ?? 0)}
          measure="₽"
        />
        <Indicator
          label="Вложения / доля инвесторов"
          value={data?.total_investment}
          measure="₽"
        />
        <Indicator
          label="Денежный баланс"
          value={data?.total_balance}
          measure="₽"
        />
        <Indicator
          label="Товарный баланс"
          value={data?.stock_balance}
          measure="₽"
        />
        <Indicator
          label="Неоплаченный налог"
          value={data?.tax_balance}
          measure="₽"
        />
      </div>
      <div className={styles['chart-container']}>
        <RecordTable
          title={
            <AddTitle
              text="Балансы"
              onAddClick={() => toggleNewPhysicalBalance(true)}
            />
          }
          columns={[
            { name: 'name', title: 'Название' },
            { name: 'current_value', title: 'Сумма', type: 'number' },
          ]}
          resourcePath="/physical-balances"
          forceUpdate={newPhysicalBalance}
          defaultSorting={[{ id: 'value', desc: true }]}
        />
        <PieChart data={data?.physical_balances} title="Балансы" />
        <PieChart data={data?.investors} title="Доля инвесторов" />
      </div>
      <EditModal
        isOpen={newPhysicalBalance}
        close={() => toggleNewPhysicalBalance(false)}
      >
        <PhysicalBalance close={() => toggleNewPhysicalBalance(false)} />
      </EditModal>
    </AppContainer>
  );
};

export default Balance;
