import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppContainer from '../../components/app-container';
import ComponentContainer from '../../components/ComponentContainer';
import RecordTable from '../../components/RecordTable';
import AddTitle from '../../components/AddTitle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createRecord, requestEntity, updateRecord } from '../../transport/api';
import { makeTitle } from '../../utils';
import { toast } from 'react-toastify';
import Input from '../../components/input';
import Button from '../../components/button';
import styles from './categories.module.css';
import EmojiButton from '../../components/EmojiButton';
import DeleteModal from '../../components/CoreTable/DeleteModal';


const MoneyOperationCategory = () => {
  const { moneyOperationCategoryId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isDeleteConfirmationOpened, toggleDeleteConfirmationOpened] = useState(false);

  makeTitle('Категории');

  const getData = async () => !!moneyOperationCategoryId && moneyOperationCategoryId !== 'new' ? requestEntity(
    '/money-operation-categories', moneyOperationCategoryId)
    .then((data) => (
      { ...data, id: data.id || moneyOperationCategoryId }
    ))
    .catch((_) => navigate(-1, { replace: true })) : { title: '', num: '', path: '' };

  const { register, handleSubmit, getValues, reset } = useForm({
    defaultValues: getData,
  });

  useEffect(() => {
    getData().then(data => reset(data));
  }, [moneyOperationCategoryId, reset]);

  const onSubmit = async (data) => {
    if (moneyOperationCategoryId === 'new') {
      createRecord('/money-operation-categories', { ...data, parent_category_id: searchParams.get('parentId') || null })
        .then((responseData) => {
          toast.success('Категория успешно создана');
          navigate(`/money-operation-categories/${responseData.id}`);
        })
        .catch((error) => toast.error(error.message));
    } else {
      updateRecord(`/money-operation-categories/${moneyOperationCategoryId}`, data)
        .then(() => toast.success('Данные успешно обновлены'))
        .catch((error) => toast.error(error.message));
    }
  };

  return (
    <AppContainer title="Категории денежных операций" containerClassname={styles.container}>
      {moneyOperationCategoryId ? (
        <ComponentContainer
          title={(
            <div className={styles['emoji-title']}>
              <EmojiButton
                title="back"
                emoji="🔙"
                className={styles['emoji-button']}
                onClick={() => navigate(`/money-operation-categories/${getValues('parent_category_id') || ''}`)}
              />
              {getValues('path')} {getValues('title')}
              {getValues('can_delete') ? <EmojiButton
                title="delete"
                emoji="🗑"
                className={styles['emoji-button']}
                onClick={() => toggleDeleteConfirmationOpened(true)}
              /> : undefined}
            </div>
          )}
          className={styles.form}
        >
          <form onSubmit={handleSubmit(onSubmit)} id="money-operation-category-form">
            <Input label="Название" register={() => register(
              'title',
              { required: true },
            )}/>
            <Input label={`Номер ${!!searchParams.get('parentId') ? '(Который будет указан после точки)' : ''}`}
                   register={() => register(
                     'num',
                     { required: true },
                   )}/>
          </form>
          <Button form="money-operation-category-form" type="submit">Сохранить</Button>
        </ComponentContainer>
      ) : undefined}
      <RecordTable
        title={(
          <AddTitle
            text={moneyOperationCategoryId ? 'Дочерние категории' : 'Категории'}
            onAddClick={() => {
              navigate(`/money-operation-categories/new?parentId=${moneyOperationCategoryId || ''}`);
            }}
          />
        )}
        columns={[
          { name: 'path', title: 'Номер', customStyle: styles['fixed-column'] },
          { name: 'title', title: 'Название' },
        ]}
        filters={[
          {
            fieldName: 'parent_id',
            value: !!moneyOperationCategoryId
              ? moneyOperationCategoryId === 'new' ? -1 : moneyOperationCategoryId
              : null,
          },
        ]}
        onClick={(row) => navigate(`/money-operation-categories/${row.id}`)}
        resourcePath="/money-operation-categories"
        defaultSorting={[{ id: 'num' }]}
      />
      <DeleteModal
        show={isDeleteConfirmationOpened}
        path={`/money-operation-categories/${moneyOperationCategoryId}`}
        title="Вы уверены, что хотите удалить категорию? Обратить будет операцию невозможно."
        close={() => toggleDeleteConfirmationOpened(false)}
        callback={() => navigate(`/money-operation-categories/${getValues('parent_category_id') || ''}`)}
      />
    </AppContainer>
  );
};

export default MoneyOperationCategory;