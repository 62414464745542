import React, { useRef, useState } from 'react';

import AppContainer from '../../components/app-container';
import AppTable from '../../components/AppTable';
import Button from '../../components/button';
import EditModal from './../../components/EditModal/index';
import EmojiButton from '../../components/EmojiButton';
import Input from '../../components/input';
import PositionalModal from '../../components/PositionalModal';
import Select from '../../components/Select';
import Operation from './Operation';

import { getDate, makeTitle } from '../../utils';
import { createRecord } from '../../transport/api';

import styles from './MoneyOperations.module.css';
import ExportReportModal from './ExportReporModal';

const MoneyOperations = () => {
  makeTitle('Денежные операции')
  const [editingOperationId, setEditingOperationId] = useState();
  const [newOperation, setNewOperation] = useState(null);

  const [description, setDescription] = useState();
  const [fromPhysicalBalanceId, setFromPhysicalBalanceId] = useState();
  const [toPhysicalBalanceId, setToPhysicalBalanceId] = useState();

  const [periodStart, setPeriodStart] = useState(getDate('date', -365));
  const [submitPeriodStart, setSubmitPeriodStart] = useState(periodStart);
  const [periodEnd, setPeriodEnd] = useState(getDate('date'));
  const [submitPeriodEnd, setSubmitPeriodEnd] = useState(periodEnd);
  const [isReportDialogOpen, toggleReportDialogOpen] = useState(false);

  const [isFilterModalOpen, toggleFilterModalOpen] = useState(false);
  const filterButton = useRef()

  return (
    <AppContainer
      title={
        <div className={styles['button-container']}>
          Денежные операции
          <EmojiButton
            onClick={() => toggleFilterModalOpen(true)}
            emoji={'🔎'}
            className={styles['filter-button']}
            ref={filterButton}
          />
          <Button
            className={styles.button}
            onClick={() => setNewOperation('INNER_TRANSFER')}
          >
            Перевод
          </Button>
          <Button
            className={styles.button}
            onClick={() => setNewOperation('OUTPUT')}
          >
            Расход
          </Button>
          <Button
            hidden
            className={styles.button}
            onClick={() => setNewOperation('PAYMENT')}
          >
            Оплата партии
          </Button>
          <Button
            hidden
            className={styles.button}
            onClick={() => createRecord('/money-operations/week-payment')}
          >
            Недельная выплата
          </Button>
          <Button
            hidden
            className={styles.button}
            onClick={() => setNewOperation('TAX')}
          >
            Оплата налога
          </Button>
          <Button
            className={styles.button}
            onClick={() => setNewOperation('WITHDRAW')}
          >
            Вывод
          </Button>
          <EmojiButton
            onClick={() => toggleReportDialogOpen(true)}
            className={styles['filter-button']}
            title="Выгрузка отчёта"
            emoji="📑"
          />
        </div>
      }
    >
      <AppTable
        columns={[
          { name: 'operation_date', title: 'Дата операции', type: 'datetime' },
          { name: 'volume', title: 'Сумма', type: 'number' },
          { name: 'goods_volume', title: 'Сумма товарная', type: 'number' },
          { name: 'operation_type_desc', title: 'Тип' },
          { name: 'description', title: 'Описание' },
          { name: 'money_path', title: 'Балансы' },
        ]}
        onClick={(row) => setEditingOperationId(row.id)}
        resourcePath="/money-operations"
        defaultSorting={[{ id: 'operation_date', desc: true }]}
        forceUpdate={`${editingOperationId}${newOperation}`}
        getDeleteProperties={(row) => ({ path: `/money-operations/${row.id}`})}
        filters={[
          { fieldName: 'description', value: description },
          { fieldName: 'from_physical_balance_id', value: fromPhysicalBalanceId },
          { fieldName: 'to_physical_balance_id', value: toPhysicalBalanceId },
          { fieldName: 'period_start', value: submitPeriodStart },
          { fieldName: 'period_end', value: submitPeriodEnd },
        ]}
      />
      <EditModal
        isOpen={!!newOperation || !!editingOperationId}
        close={() => {
          setEditingOperationId(undefined);
          setNewOperation(undefined);
        }}
      >
        <Operation
          close={() => {
            setEditingOperationId(undefined);
            setNewOperation(undefined);
          }}
          onlyShared={newOperation === 'TAX'}
          newOperation={newOperation}
          editingOperationId={editingOperationId}
        />
      </EditModal>
      <PositionalModal
        positionFrom={{ left: filterButton.current?.offsetLeft - 4, top: filterButton.current?.offsetTop }}
        isOpen={isFilterModalOpen}
        height="15em"
        width="22em"
        close={() => toggleFilterModalOpen(false)}
      >
        <Select
          value={description}
          onChange={(value) => setDescription(() => value)}
          resourcePath="/money-operations/descriptions"
          filters={[
            { fieldName: 'period_start', value: submitPeriodStart },
            { fieldName: 'period_end', value: submitPeriodEnd },
          ]}
          optionsColumns={{ value: 'description', label: 'description' }}
          label="Описание"
          forceUpdate
        />
        <Select
          value={toPhysicalBalanceId}
          onChange={(value) => setFromPhysicalBalanceId(() => value)}
          resourcePath="/money-operations/physical-balances"
          optionsColumns={{ value: 'id', label: 'name' }}
          label="Баланс списания"
          forceUpdate
        />
        <Select
          value={toPhysicalBalanceId}
          onChange={(value) => setToPhysicalBalanceId(() => value)}
          resourcePath="/money-operations/physical-balances"
          optionsColumns={{ value: 'id', label: 'name' }}
          label="Баланс зачисления"
          forceUpdate
        />
        <div className={styles['date-filter-container']} >
          <Input
            type="date"
            value={periodStart}
            onChange={(e) => setPeriodStart(() => e.target.value)}
            options={{
              onBlur: () => setSubmitPeriodStart(periodStart)
            }}
            isFilter
          />
          <div>—</div>
          <Input
            type="date"
            value={periodEnd}
            onChange={(e) => setPeriodEnd(() => e.target.value)}
            options={{
              onBlur: () => setSubmitPeriodEnd(periodEnd)
            }}
            isFilter
          />
        </div>
      </PositionalModal>
      <ExportReportModal isOpen={isReportDialogOpen} close={() => toggleReportDialogOpen(false)} />
    </AppContainer>
  );
};

export default MoneyOperations;
