import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createRecord, requestEntity, requestTable, updateRecord } from '../../transport/api';
import Input from '../../components/input';

import ComponentContainer from '../../components/ComponentContainer/index';
import Button from '../../components/button/index';
import { toast } from 'react-toastify';
import UncontrolledSelect from '../../components/UncontrolledSelect';
import Switch from '../../components/Switch';
import { getDate } from '../../utils';
import Alert from '../../components/Alert';

import styles from './Operation.module.css';


const getDescriptionByType = (operationType, isInvestorOperation) => {
  if (isInvestorOperation) {
    return {
      WITHDRAW: 'Вывод средств инвестором',
      DEPOSIT: 'Внесение средств инвестором',
    }?.[operationType] ?? ''
  }
  return {
    INNER_TRANSFER: 'Внутренний перевод',
    TAX: 'Оплата налога',
    PAYMENT: 'Оплата партии',
    DEPOSIT: 'Ввод тела',
    WITHDRAW: 'Вывод тела',
  }?.[operationType] ?? '';
};

const Operation = ({
  close,
  newOperation,
  editingOperationId,
  investorId,
  defaultVolume,
  defaultFromPhysicalBalanceId,
  defaultToPhysicalBalanceId,
  fromGoodsId,
  toGoodsId,
  onlyShared,
}) => {
  const [operationType, setOperationType] = useState(newOperation);
  const [isShared, toggleShared] = useState(onlyShared);
  const [operationInvestorId, setOperationInvestorId] = useState(investorId);
  const [loading, toggleLoading] = useState(false);
  const [physicalBalances, setPhysicalBalances] = useState([]);

  useEffect(() => {
    requestTable('/money-operations/physical-balances', [], [], 1, 300).then((data) => {
      setPhysicalBalances(() =>
        data?.list.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      );
    });
  }, [setPhysicalBalances]);

  const { register, handleSubmit, control } = useForm({
    defaultValues: !editingOperationId
      ? {
        operation_type: newOperation,
        is_shared: (
          operationType === 'TAX' && onlyShared
        ) ? 'shared_true' : 'shared_false',
        is_initialization: 'initialization_false',
        investor_id: investorId,
        volume: defaultVolume || 0,
        goods_volume: 0,
        tax_volume: 0,
        operation_date: getDate(),
        from_physical_balance_id: defaultFromPhysicalBalanceId,
        to_physical_balance_id: defaultToPhysicalBalanceId,
        from_goods_id: fromGoodsId,
        to_goods_id: toGoodsId,
        description: getDescriptionByType(newOperation, !!investorId),
      }
      : async () =>
        requestEntity('/money-operations', editingOperationId).then(
          (data) => {
            toggleShared(data.is_shared);
            setOperationType(data.operation_type);
            setOperationInvestorId(data.investor_id);
            return {
              ...data,
              operation_date: data.operation_date.slice(0, 16),
              change_date: data.change_date.slice(0, 16),
              is_shared: data.is_shared
                ? 'shared_true'
                : 'shared_false',
            };
          },
        ),
  });

  const onSubmit = async (data) => {
    if (!editingOperationId) {
      toggleLoading(true);
      return createRecord(`/money-operations/new`, {
        ...data,
        is_shared: data.is_shared === 'shared_true' || isShared,
        is_initialization: data.is_initialization === 'initialization_true',
      })
        .then(() => {
          toast.success('Операция успешно добавлена');
          close();
        })
        .catch((error) => toast.error(error.message))
        .finally(() => toggleLoading(false));
    }
    return updateRecord(`/money-operations/${editingOperationId}`, {
      description: data.description,
      goods_volume: data.goods_volume,
    }).then(() => {
      toast.success('Операция успешно обновлена');
      close();
    }).catch((error) => toast.error(error.message))
      .finally(() => toggleLoading(false));
  };

  return (
    <ComponentContainer className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} id="money-operations-form">
        <UncontrolledSelect
          control={control}
          name="operation_type"
          label="Тип операции"
          disabled
          resourcePath="/money-operations/types"
          optionsColumns={{ value: 'value', label: 'label' }}
        />
        {!(
          operationType === 'TAX' && onlyShared
        ) ? (
          <Input
            label="Сумма"
            type="number"
            options={{ min: 0.0 }}
            register={() =>
              register('volume', {
                required: true,
                disabled: !!editingOperationId,
              })
            }
          />
        ) : undefined}
        {['PAYMENT', 'INPUT', 'OUTPUT'].includes(operationType) ? (
          <Input
            label="Сумма (товарная)"
            type="number"
            register={() =>
              register('goods_volume', {
                required: true,
                disabled: !!editingOperationId && operationType !== 'PAYMENT',
              })
            }
          />
          ) : undefined
        }
        {['INPUT'].includes(operationType) ? (
          <Input
            label="Сумма (налоги)"
            type="number"
            register={() =>
              register('tax_volume', {
                required: true,
                disabled: !!editingOperationId,
              })
            }
          />
          ) : undefined
        }
        {operationType === 'TAX' ? <Alert>
          Для корректного списания налога, необходимо выбрать дату, месяц которой является следующей, после налогового
          периода. Например - если платятся налоги за III квартал, то необходимо выбрать дату в октябре.
        </Alert> : undefined}
        <Input
          label="Дата операции"
          type="datetime-local"
          register={() =>
            register('operation_date', {
              required: true,
              disabled: !!editingOperationId,
            })
          }
        />
        {!(
          operationType === 'TAX' && onlyShared
        ) ? <Input
          label="Описание"
          register={() =>
            register('description', {
              required: true,
              disabled: !newOperation && isShared,
            })
          }
        /> : undefined}
        {operationType === 'OUTPUT' ?
          <UncontrolledSelect
            control={control}
            name="category_id"
            label="Категория расхода"
            resourcePath="/money-operation-categories/flatten"
            optionsColumns={{ value: 'id', label: 'description' }}
            disabled={!!editingOperationId}
          /> : undefined
        }
        {operationType === 'PAYMENT' ? (
          <Input
            label="Штук в партии"
            type="number"
            options={{ min: 1 }}
            register={() =>
              register('shipment_count', {
                required: true,
                disabled: !!editingOperationId,
              })
            }
          />
        ) : undefined}
        {['OUTPUT', 'INNER_TRANSFER', 'PAYMENT', 'TAX', 'DEPOSIT', 'WITHDRAW'].includes(operationType) && !isShared && !(
          operationType === 'DEPOSIT' && !!operationInvestorId
        )? (
          <UncontrolledSelect
            control={control}
            name="from_physical_balance_id"
            label="Баланс списания"
            customOptions={physicalBalances}
            disabled={!!editingOperationId}
          />
        ) : undefined}
        {['INNER_TRANSFER', 'INPUT', 'DEPOSIT'].includes(operationType) && !(
          operationType === 'WITHDRAW' && !!operationInvestorId
        )  ? (
          <UncontrolledSelect
            control={control}
            name="to_physical_balance_id"
            label="Баланс зачисления"
            customOptions={physicalBalances}
            disabled={!!editingOperationId}
          />
        ) : undefined}
        {['DEPOSIT', 'WITHDRAW'].includes(operationType) &&
        !!operationInvestorId ? (
          <UncontrolledSelect
            control={control}
            name="investor_id"
            label="Инвестор"
            resourcePath="/money-operations/investors"
            optionsColumns={{ value: 'value', label: 'label' }}
            disabled={true}
          />
        ) : undefined}
        {operationType === 'DEPOSIT' && !!operationInvestorId ? (
          <Switch
            label="Инициализация инвестора?"
            options={[
              { value: 'initialization_true', label: 'Да' },
              { value: 'initialization_false', label: 'Нет' },
            ]}
            register={() =>
              register('is_initialization', {
                disabled: !!editingOperationId,
              })
            }
          />
        ) : undefined}
      </form>
      <Button form="money-operations-form" type="submit" disabled={loading}>
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default Operation;
