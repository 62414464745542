import styles from './card.module.css';
import Indicator from '../../../components/indicator';
import ComponentContainer from '../../../components/ComponentContainer';
import RecordTable from '../../../components/RecordTable';
import EditModal from '../../../components/EditModal';
import Operation from '../../operations/Operation';
import React, { useEffect, useState } from 'react';
import { requestEntity } from '../../../transport/api';


const Cash = ({ goodsId, isGoodsEdit, isLinkVariantsOpen, newOperation }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [operation, setOperation] = useState();

  useEffect(() => {
    requestEntity('/goods', goodsId).then((data) => {
      setData(() => data);
      setLoading(false);
    });
  }, [goodsId, isGoodsEdit, newOperation, isLinkVariantsOpen]);

  return (
    <div>
      <div className={styles['indicator-container']}>
        <Indicator
          key="revenue"
          label="Выручка"
          value={Math.round(data?.revenue)}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="profit"
          label="Прибыль"
          value={Math.round(data?.profit)}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="sales"
          label="Продажи"
          value={data?.sales}
          measure="шт."
          loading={loading}
        />
        <Indicator
          key="total_supply_balance"
          label="Товарный баланс"
          value={data?.goods_balance}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="tax"
          label="Неоплаченный налог"
          value={data?.tax}
          measure="₽"
          loading={loading}
        />
      </div>
      <div className={styles['image-table-container']}>
        <ComponentContainer loading={loading}>
          <img
            src={data?.media_url || '/icons/Image 2.svg'}
            alt="Изображение проекта"
            className={`${styles.image} ${
              !data?.media_url ? styles['no-image'] : ''
            }`}
          />
        </ComponentContainer>
        <RecordTable
          title="История последних транзакций"
          columns={[
            { name: 'operation_date', title: 'Дата', type: 'date', customStyle: styles['fixed-column'] },
            {
              name: 'volume',
              title: 'Сумма',
              type: 'number',
              customStyle: styles['fixed-column'],
            },
            {
              name: 'goods_volume',
              title: 'Тов. сумма',
              type: 'number',
              customStyle: styles['fixed-column'],
            },
            {
              name: 'operation_type_desc',
              title: 'Тип',
              customStyle: styles['fixed-column'],
            },
            { name: 'description', title: 'Комментарий' },
          ]}
          resourcePath={`/goods/${goodsId}/operations`}
          defaultSorting={[{ id: 'operation_date', desc: true }]}
          forceUpdate={`${newOperation} ${!!operation?.newOperation}`}
          onClick={(row) => {
            setOperation({
              newOperation: row.operation_type,
              editingOperationId: row.main_operation_uid,
              defaultVolume: row.volume,
              defaultFromBalanceName: row.balance_from_name,
              defaultToBalanceName: row.balance_to_name
            })
          }}
          getDeleteProperties={(row) => ({ path: `/money-operations/${row.main_operation_uid}`})}
        />
        <EditModal close={() => setOperation(undefined)} isOpen={!!operation?.newOperation}>
          <Operation
            close={() => setOperation(undefined)}
            newOperation={operation?.newOperation}
            editingOperationId={operation?.editingOperationId}
            investorId={operation?.investorId}
            defaultVolume={operation?.defaultVolume}
            defaultFromBalanceName={operation?.defaultFromBalanceName}
            defaultToBalanceName={operation?.defaultToBalanceName}
            onlyShared={operation?.onlyShared}
          />
        </EditModal>
      </div>
    </div>
  )
}

export default Cash;
